import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component';

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p>With effect from Tuesday 3nd August 2021, we will be making the following changes to our mortgage product
      range:</p>

    <p><strong>Summary of changes</strong></p>
    <p>We are introducing differential pricing which includes products for existing mortgage customers switching,
      existing mortgage customers borrowing more and new business.</p>

    <p><strong>Existing Mortgage Customer Range:</strong></p>
    <p>The existing mortgage customer range will be available for existing mortgage customers switching or borrowing
      more and a selection of these rates will be priced lower than the new business alternative.</p>
    <p>The rates can be found on the updated Mortgage Rate Sheet. We have made some amendments to the Mortgage Rate
      Sheet which include a hyperlinked contents page to make this easier for you to navigate to the relevant mortgage
      rates dependant on your customer.</p>

    <ul>
      <li>2 Year Fixed Standard at 85%, 90% and 95% (MGS) LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 85%, 90% and 95% (MGS) LTV have reduced</li>
      <li>3 Year Fixed Standard at 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 85%, 90% and 95% (MGS) LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 85%, 90% and 95% (MGS) LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 85% and 90% LTV have reduced</li>
    </ul>

    <p>There are no other changes to our rates or fixed rate end dates.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 3nd August 2021 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate
      sheet</a></p>

  </NewsArticle>
);

export default Article;
